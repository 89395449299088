<template>
  <overlay-scrollbars class="page page-bosBusiness" ref="os">
    <div class="box box__offers">
      <div class="header"></div>
      <div class="content">
        <div class="block">
          <div class="hdr offers-hdr">
            <div class="ttl">Public list</div>
            <div class="btn btn__createOffer" @click="openCreateOfferModal">Create</div>
            <!--<div
                @click.prevent="downloadExcel"
                v-if="$store.state.user.is_super_admin"
                class="btn btn__createOffer">
                Download to Excel
              </div>-->
            <div
              class="btn show-providers-offers show-providers-offers-active"
              @click="showProvidersOffers = !showProvidersOffers"
            >
              Provider
            </div>
          </div>
          <div class="hdr offers-hdr">
            <div>
              <input class="inpt" name="offer_id" v-model="searchOfferId" placeholder="Enter Offer Id" @keyup.enter="getOffers({searchOfferId: searchOfferId, page: 1})" />
            </div>
            <select style="margin-left: 24px" name="country" class="inpt" v-model="search_country" @change="getOffers({country: search_country, page: 1})">
              <option :value="null">Select country</option>
              <option 
                v-for="(country, i) in countries" 
                :value="country.id"
              >
                {{ country.name }}
              </option>
            </select>
            <div style="margin-left: 24px">
              <Tmblr :active="!!is_top" :class="'c-tmblr'" :text="'Show top licenses'" @changeState="is_top = !is_top; getOffers({is_top: is_top, page: 1})" />
            </div>
            <div class="btn btn__createOffer" @click="getOffers({searchOfferId: searchOfferId, is_top: is_top, page: 1})">Search</div>
          </div>
          <Table
            :options="offersTableData"
            @editItem="editOffers"
            @showNewVersion="showNewVersion"
            @deleteItem="deleteOffers"
            @toggleProperty="toggleProperty"
            @change-page="getOffers"
            @scroll-top="toElTop"
            @sort="sortBy"
          />
        </div>
      </div>
    </div>
    <div class="box box__feedback">
      <div class="header"></div>
      <div class="content">
        <div class="block">
          <div class="hdr">
            <div class="ttl">Seller info</div>
          </div>
          <Table
            :options="getTableData"
            @more-modal="openMoreModal"
            @change-page="getFeedback"
            @scroll-top="toElTop"
          />
        </div>
      </div>
    </div>
    <div class="box box__feedback">
      <div class="header"></div>
      <div class="content">
        <div class="block">
          <div class="hdr">
            <div class="ttl">Listed ready license</div>
          </div>
          <Table
            :options="appliedBosTableData"
            @show-user-bos-offers="showUserBusinessOffers"
          />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import Table from '@/components/elem/Table'
import Tmblr from '@/components/elem/Tmblr'

export default {
  name: 'BuyOrSellBusiness',
  components: {
    Table,
    Tmblr,
  },
  data: () => ({
    feedbackTableData: {
      body: [],
      paginationData: null,
      businesOffersKey: 1,
      businesOffers: {
        colsWidth: ['4%', '17%', '17%', '16%', '34%', '12%'],
        header: [
          { ttl: '#' },
          { ttl: 'Type of Business' },
          { ttl: 'Type of License' },
          { ttl: 'Country' },
          { ttl: 'Description' },
          { ttl: 'Request' }
        ],
      },
    },
    showProvidersOffers: false,
    searchOfferId: null,
    is_top: false,
    countries: [],
    search_country: null,
  }),
  computed: {
    ...mapState({
      offersTableData: state => state.bos_business.offersTableData,
      appliedBosTableData: state => state.bos_business.appliedBosTableData,
    }),
    getTableData () {
      let data = {
        colsWidth: ['6%', '37%', '37%', '15%', '5%'],
        header: [
          { ttl: 'ID' },
          { ttl: 'Email' },
          { ttl: 'Messengers' },
          { ttl: 'Created At' },
          { ttl: 'More' }
        ],
        body: [],
        paginationData: this.feedbackTableData.paginationData
      }

      if (this.feedbackTableData.body.length > 0) {
        let bodyArr = []
        this.feedbackTableData.body.forEach(el => {
          bodyArr.push([
            el[0],
            el[1],
            el[3],
            el[6],
            { type: 'more' }
          ])
        })
        data.body = bodyArr
      }
      return data
    }
  },
  async created () {
    await this.getCountries()
    this.setProvidersOffers(false)
    this.getFeedback()
    this.getOffers()
    this.getAppliedBosData()
  },
  mounted(){

  },
  methods: {
    ...mapActions({
      getOffers: 'bos_business/getOffers',
      getAppliedBosData: 'bos_business/getAppliedBosData'
    }),
    ...mapMutations({
      addSuperAdminCol: 'bos_business/addSuperAdminCol',
      setProvidersOffers: 'bos_business/setProvidersOffers',
      setOffersTableHeader: 'bos_business/setOffersTableHeader',
      setOffersTableColWidth: 'bos_business/setOffersTableColWidth',
    }),
    async getCountries () {
      await axios.get('admin/data/countries')
      .then(({data}) => {
        this.countries = data
      })
      .catch((error) => {
        console.log(error);
      })
    },
    async getFeedback (page = 1) {
      await axios.get('admin/business-feedback?page='+ page)
        .then(({ data }) => {
          this.feedbackTableData.paginationData = data
          this.feedbackTableData.body = data.data
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    openMoreModal (id) {
      let data = this.feedbackTableData.body.find(el => +el[0] === id).slice()

      this.$store.commit('setModal', {
        template: 'neo-bank-more',
        options: {
          names: ['ID', 'Email', 'Person', 'Messengers', 'Social', 'Price', 'Created At'],
          values: data
        }
      })
    },
    openCreateOfferModal () {
      this.$store.commit('setModal', {
        template: 'create-business-offer'
      })
    },
    editOffers (obj) {
      const item = this.offersTableData.rawData.find(el => +el[0] === obj.id)
      this.$store.commit('setModal', {
        template: 'create-business-offer',
        options: {
          id: obj.id,
          form: {
            business_type: item[1],
            license_type: item[2],
            country_name: item[3],
            description: item[4],
            price: item[5],
            license_number: item[8],
            is_verified: item[9],
            is_kyb_done: item[10],
            is_kyc_done: item[11],
            customer_email: item[12],
            seller_name: (this.$store.state.user.is_super_admin) ? item[15] : null,
            seller_contacts: (this.$store.state.user.is_super_admin) ? item[16] : null,
            user: !item[6].customer_email ? item[6].from : null,
            long_description: item[17],
            fintech_type_name: item[18],
            is_top: item[19],
            manager_name: item[20],
            seller_number: item[21],
            date: item[22],
          }
        }
      })
    },
    showNewVersion(obj){
      const item = this.offersTableData.rawData.find(el => +el[0] === obj.id)

      let newOffer = obj.newOffer

      this.$store.commit('setModal', {
        template: 'new-version-business-offer',
        options: {
          id: obj.id,
          form: {
            new_business_type: newOffer.new_business_type,
            new_license_type: newOffer.new_license_type,
            new_description: newOffer.new_description,
            new_wish_price: newOffer.new_wish_price,
            new_country_id: newOffer.new_country_id,
            new_currency_id: newOffer.new_currency_id,

            business_type: item[1],
            license_type: item[2],
            country_name: item[3],
            description: item[4],
            price: item[5],
          }
        }
      })
    },
    toggleProperty(obj){
      if(obj.id){
        let urlPart = '';
        
        if(obj.property == 'is_top'){
          urlPart = 'toggle-top';
        }
        
        let that = this
        
        axios.post('admin/business-offers/' + obj.id + '/' + urlPart)
        .then(function (response) {
          let currentPage = that.offersTableData.paginationData.meta.current_page;
          
          that.getOffers(currentPage)
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    deleteOffers (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'business-offers',
          type: 'business offer',
          url: 'admin/business-offers/' + obj.id,
          successfullMessage: 'You successfully deleted business offer',
        }
      })
    },
    toElTop (el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
    showUserBusinessOffers (payload) {
      this.$router.push({ name: "CustomerDetail", params: { id: payload.id, viewAppliedOffers: true, 'scroll': 'bos' }})
    },
    sortBy (data) {
      this[data.obj].sort.loading = true
      this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
        ? 'desc' : 'asc'
      this[data.obj].sort.name = data.sort

      switch (data.obj) {
        case 'offersTableData':
          this.getOffers()
          break;
      }
    },
    downloadExcel () {
      return null;
      if(this.$store.state.user.is_super_admin){
        axios.post('admin/business-offers/create-excel-report')
        .then(function (response) {
          const url = response.data
          const link = document.createElement('a')
          link.href = url
          document.body.appendChild(link)
          link.click()
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    }
  },
  watch: {
    showProvidersOffers: function(showProvidersOffers) {
      /*this.setOffersTableHeader(h)
      if (showProvidersOffers) {
        const colsWidth = ['4%', '14%', '14%', '14%', '24%', '11%', '10%', '10%']
        this.setOffersTableColWidth(colsWidth)
        const header = this.offersTableData.header
        header.splice(7, 0, { ttl: 'Provider Id', info: null, provider: true})
        header.splice(8, 0, { ttl: 'Provider', info: null, provider: true})
        this.setOffersTableHeader(header)
      } else {
        const colsWidth = ['4%', '14%', '14%', '14%', '24%', '11%', '10%', '10%']
        this.setOffersTableColWidth(colsWidth)
      }*/
     
      this.setProvidersOffers(showProvidersOffers)
      this.getOffers()
    },
    '$store.state.user': function(){
      this.addSuperAdminCol(this.$store.state.user.is_super_admin)
      this.getOffers()
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  .box {
    &__feedback,
    &__offers {
      .content {
        padding: 0 0 1rem;

        .block {
          margin-bottom: 1.5rem;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: .5rem;

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
              font-size: 20px;
            }

            .btn {
              border-radius: .5rem;
              padding: .5rem 1.5rem;
              margin-left: 1.75rem;
              background-color: #1763fb;
            }
          }
        }
      }
    }
  }

  ::v-deep .tbl {
    &_header,
    &_body {
      .col:nth-child(2),
      .col:nth-child(3) {
        justify-content: flex-start;

        span {
          text-align: left;
        }
      }
    }
  }
}

.offers-hdr {
  position: relative;
}

.excel-btn-wrapper {
  position: relative;
  height: 67px;
}

.excel-btn {
  border-radius: 0.5rem; 
  padding: 10px; 
  margin-left: 30px; 
  border: 1px solid #1763fb;
  display: inline-block;
  position:absolute;
  right: 15px;
  top: 0;
}

.show-providers-offers {
  border-radius: 1rem;
  padding: 10px;
  margin-left: 30px;
  position:absolute;
  right: 3px;
  top: 0;

  &-active {
    background-color: #1763fb !important;
  }

  &-not-active {
    background-color: #2F363D !important;
  }
}
::v-deep .c-tmblr .txt{
  color: #fff;
}
</style>
