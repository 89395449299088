<template>
  <!-- <div id="sidebar" :class="{ 'sidebar-show': showSidebar }"> -->
  <div id="sidebar" :class="{ 'sidebar-show': showSidebar }">
    <div class="btn btn_burger"  :class="{ 'btn_burger-show': showSidebar }" @click="showSidebar = !showSidebar">
      <svg v-if="!showSidebar" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
        <rect x="0" y="277.333" width="384" height="42.667"/>
        <rect x="0" y="170.667" width="384" height="42.667"/>
        <rect x="0" y="64" width="384" height="42.667"/>
      </svg>
      <Close v-else />
    </div>
    <div class="header" :class="{ 'header-show': showSidebar }">
      <Logo class="logo"/>
    </div>
    <div class="main" :class="{ 'main-show': showSidebar }">
      <div class="avatar">
        <div class="desc">
          Hello, {{ ($store.state.user) ? $store.state.user.member_login : '' }}
        </div>
        <router-link v-if="$store.state.user && $store.state.user.is_super_admin" :to="{ name: 'Balance' }" class="btn btn_balance">
          Balance <!--{{ number_format(balance, 2, '.', ' ') }}€-->
        </router-link>
      </div>
      <div class="links">
        <router-link
          v-for="(item, key) in links"
          :key="key"
          :to="{ name: item.routeName }"
          class="item"
          :class="{
            'item-active': $route.name == item.routeName,
            'soon': item.сomingSoon,
            'bright': item.bright,
            'color-green': item.color == 'green',
            'color-blue': item.color == 'blue',
          }"
          @click.native="showSidebar = false"
        >
          <component :is="item.imgComponent" class="img"></component>
          <div class="desc">{{ item.desc }}</div>
          <span class="soon_label">Coming soon</span>
        </router-link>
      </div>
      <div class="banner" @click="openChat">
        <span class="dots dots-top"><Dots/></span>
        <span class="dots dots-bottom"><Dots/></span>
        <div class="content">
          <div>N5 Touch</div>
          <div>Chat</div>
        </div>
        <div class="btn"><Arrow class="img"/></div>
      </div>
    </div>
    <div class="footer" :class="{ 'footer-show': showSidebar }">
      {{ $store.getters.contentArr.all_rights_reserved }}
    </div>
  </div>
</template>

<script>
import Logo from './../components/img/Logo.vue'
import Employment from './../components/img/Employment.vue'
import Calendar from './../components/img/Calendar.vue'
import Bank from './../components/img/Bank.vue'
import Contract from './../components/img/Contract.vue'
import Briefcase from './../components/img/Briefcase.vue'
import StampDocument from './../components/img/StampDocument.vue'
import Atlas from './../components/img/Atlas.vue'
import PaymentModule from './../components/img/PaymentModule.vue'
import Arrow from './../components/img/Arrow.vue'
import Dots from './../components/img/Dots.vue'
import ArrowFlat from './../components/img/ArrowFlat.vue'
import Close from './../components/img/Close.vue'

export default {
  components: {
    Logo,
    Employment,
    Calendar,
    Bank,
    Contract,
    Briefcase,
    StampDocument,
    Atlas,
    PaymentModule,
    Arrow,
    Dots,
    ArrowFlat,
    Close,
  },
  data: () => ({
    logo: '',
    rating: 68,
    showRateInfo: false,
    showSidebar: false,
    systemBalance: {
      total_balance: 0
    },
    links: [
      {
        routeName: 'Customer',
        imgComponent: 'Employment',
        desc: 'Customer',
        bright: true,
        color: 'green',
      },
      {
        routeName: 'Request',
        imgComponent: 'Calendar',
        desc: 'Requests',
        bright: true,
      },
      {
        routeName: 'Leads',
        imgComponent: 'Briefcase',
        desc: 'Internal Deals',
        bright: true,
        color: 'blue'
      },
      {
        routeName: 'Strategies',
        imgComponent: 'Briefcase',
        desc: 'Strategies',
        bright: true,
      },
      {
        routeName: 'Tasks',
        imgComponent: 'Briefcase',
        desc: 'Tasks',
        bright: true,
      },
      {
        routeName: 'LegalAtlas',
        imgComponent: 'StampDocument',
        desc: 'Legal Providers',
        bright: true,
      },
      {
        routeName: 'AccountRequests',
        imgComponent: 'Briefcase',
        desc: 'Bank Providers',
        bright: true,
      },
      {
        routeName: 'BuyOrSellBusiness',
        imgComponent: 'Briefcase',
        desc: 'License List',
        bright: true,
      },
      {
        routeName: 'SoftwareNet',
        imgComponent: 'Briefcase',
        desc: 'Software Net',
        bright: true,
      },
      {
        routeName: 'LegalProvider',
        imgComponent: 'Employment',
        desc: 'Referal Partners',
        bright: true,
      },
      /*{
        routeName: 'Offer',
        imgComponent: 'Bank',
        desc: 'Offer',
      },
      {
        routeName: 'Accounts',
        imgComponent: 'Briefcase',
        desc: 'Accounts',
      },
      {
        routeName: 'PaymentAtlas',
        imgComponent: 'Atlas',
        desc: 'Payment Atlas',
        сomingSoon: true
      },
      {
        routeName: 'NeoBank',
        imgComponent: 'Bank',
        desc: 'NEO Bank'
      },*/
    ]
  }),
  updated() {
    this.getTotalBalance();
  },
  methods: {
    openChat () {
      if (this.$route.name != 'Chat') {
        this.$router.push({ name: "Chat" })
      } else {
        this.$router.go()
      }
    },
    getTotalBalance(){
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/settings/total-balance')
        .then(function (response) {
          if (response.data.success) {
            that.systemBalance.total_balance = response.data.result
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    }
  },
  computed: {
    strokePosition() {
      return 590 / 100 * this.rating
    },
    spinerRotate() {
      return 360 / 100 * this.rating
    },
    balance() {
      return (this.$store.state.user) ? this.$store.state.user.balance : 0
    }
  },
  mounted() {
    // console.log(this.$route);
  },
  watch: {
    '$store.state.user': function() {
      this.logo = (this.$store.state.user.logo) ? process.env.VUE_APP_BACKEND_URL + '/storage/images/users/logos/origin/' +  this.$store.state.user.logo : '/img/default-avatar.png';
      if (this.$store.state.user.is_super_admin) {
        const adminLink = this.links.find(link => link.routeName === 'Admins')
        if (!adminLink) {
          /*this.links.push({
            routeName: 'Admins',
            imgComponent: 'Employment',
            desc: 'Administrators'
          })*/

          this.links.splice(9, 0, {
              routeName: 'Admins',
              imgComponent: 'Employment',
              desc: 'Administrators',
              bright: true,
          });
          
          for (let i = 4; i < this.links.length; i++) {
              this.links[i].position++;
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#sidebar {
  // display: none !important;
  position: fixed;
  width: var(--sb_width);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #000000;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  z-index: 99;

  &.sidebar-show {
    width: 20rem;
  }

  .btn_burger {
    display: none;
    width: 2.75rem;
    height: 2.75rem;
    padding: .25rem;
    margin: 1rem auto;

    @media (max-width: 1280px) {
      display: block;
    }

    ::v-deep svg {
      width: 100%;
      height: 100%;

      rect {
        fill: #1763fb;
      }
    }

    &-show {
      display: none;
      position: absolute;
      top: -.25rem;
      right: -3.25rem;
      width: 2.5rem;
      height: 2.5rem;
      background: var(--gr_green);
      border-radius: 50%;

      @media (max-width: 1280px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep svg {
        width: 65%;
        height: 65%;

        path {
          fill: #ffffff;
        }
      }
    }
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-height: 900px) {
      padding-top: 1rem;
    }

    @media (max-width: 1280px) {
      display: none;

      &-show {
        display: flex;
      }
    }

    .logo {
      max-width: 15rem;
      height: var(--header_height);

      @media (max-height: 900px) {
        height: calc(var(--header_height) - 2rem);
      }

      @media (max-height: 750px) {
        height: calc(var(--header_height) - 3rem);
      }
    }
  }

  .main {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1280px) {
      display: none;

      &-show {
        display: flex;
      }
    }

    .avatar {
      padding: .5rem 1.5rem;
      text-align: center;

      .circle {
        position: relative;
        width: 200px;
        height: 200px;
        margin: 0 auto;

        @media (max-height: 720px) {
          display: none;
        }

        svg {
          width: 200px;
          height: 200px;
        }

        .bg {
          fill: none;
          stroke-width: .75rem;
          stroke: #F0F2F7;
          stroke-opacity: 0.2;
        }

        .progress {
          fill: none;
          stroke-width: .75rem;
          stroke: #1763fb;
          stroke-linecap: round;
          stroke-dasharray: 1, 590;
          transform: rotate(-90deg);
          transform-origin: 50% 50%;
        }

        .img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 10rem;
          height: 10rem;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .spiner {
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
          transform-origin: right;
          z-index: 1;

          .btn {
            position: absolute;
            top: -0.75rem;
            right: -1.25rem;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            background-color: #E2F4EC;
            background-image: url('/img/smile.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 65%;

            .arrow-wrapper {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 50%;
              transform-origin: bottom;
              transform: rotate(90deg);

              .arrow {
                position: absolute;
                bottom: 0;
                left: 100%;
                transform: translateY(50%);
                width: .75rem;
                height: .75rem;
              }
            }
          }
        }

        .rateInfo {
          position: absolute;
          top: 52%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 55%;
          height: 55%;

          &-top {
            width: 100%;
            height: 50%;
            text-align: center;
            font-size: 3rem;
            font-weight: 600;
            color: #ffffff;
          }

          &-bottom {
            width: 100%;
            height: 50%;
            color: #1763fb;
            font-size: 11px;
            text-transform: uppercase;
            text-align: center;
            padding-top: .25rem;
          }
        }
      }

      .desc {
        padding: .25rem 1rem 0;
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;

        @media (max-height: 720px) {
          padding-top: 1rem;
        }
      }

      .btn_balance {
        font-size: 1.25rem;
        font-weight: 700;
        background: linear-gradient(113.24deg, #1763fb 16.01%, #1763fb 106.71%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #1763fb;
      }
    }

    .links {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 1rem 0;

      @media (max-height: 900px) {
        padding: .25rem 0;
      }

      .item, a.item {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // padding: .5rem 0 .5rem 2rem;
        padding-left: 2rem;
        color: #ababab;
        text-decoration: unset;
        &.bright{
          background: linear-gradient(90deg, transparent 10%, rgba(255, 255, 255, .75));
          color: #fff;
        }
        &:hover {
          background: linear-gradient(269.97deg, rgba(23, 99, 251, 0.13) 0.01%, rgba(23, 99, 251, 0) 99.96%);
        }
        &.color-blue{
          //background: linear-gradient(90deg, transparent 10%, rgb(23,99,251, .75));
          color: #0000CC;
        }
        &.color-green{
          background: linear-gradient(90deg, transparent 10%, rgb(18,234,45, .75));
        }
        &-active {
          background: linear-gradient(269.97deg, rgba(23, 99, 251, 0.13) 0.01%, rgba(23, 99, 251, 0) 99.96%);

          .desc::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: .75rem;
            height: 100%;
            background: blueviolet;
            border-radius: 6px 0 0 6px;
          }
        }

        .img {
          width: 1.75rem;
          height: 1.75rem;
          margin: .5rem;
        }

        .desc, a.desc {
          padding: 1rem .75rem;
          font-weight: 600;
        }

        .soon_label {
          display: none;
        }

        &.soon {
          color: #9F9F9F;

          // &:hover {
          //   background: inherit;
          // }

          .soon_label {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: .25rem;
            right: .75rem;
            color: #9F9F9F;
            background: #22252B;
            border-radius: 6px;
            font-size: 10px;
            padding: 2px 10px;
            opacity: .6;
          }
        }
      }
    }

    .banner {
      position: relative;
      width: 14rem;
      height: 7rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: var(--boxRadius);
      background: linear-gradient(113.24deg, #1763fb 16.01%, #1763fb 106.71%);
      padding: .5rem .75rem;
      overflow: hidden;

      @media (max-height: 820px) {
        display: none;
      }

      .dots {
        position: absolute;

        &-top {
          top: -6px;
          left: 1.25rem;
          width: 1.5rem;
        }

        &-bottom {
          bottom: -6px;
          right: 0.5rem;
          width: 1.75rem;
          transform: rotate(90deg);
          opacity: 0.5;
        }
      }

      .content {
        width: 65%;
        font-weight: 600;
        margin: .25rem;
      }

      .btn {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: .25rem;
        margin: .25rem;
        background-color: var(--c_more-black);
        display: flex;
        align-items: center;
        justify-content: center;

        .img {
          width: 1.25rem;
        }
      }
    }
  }

  .footer {
    width: 100%;
    text-align: center;
    font-size: .75rem;
    padding: 1rem 1.5rem;
    color: #969BA0;

    @media (max-width: 1280px) {
      display: none;

      &-show {
        display: flex;
      }
    }
  }
}
</style>
