export default ({
  namespaced: true,
  state: () => ({
    offersTableData: {
      objName: 'offersTableData',
      colsWidth: ['3%', '11%', '11%', '12%', '10%', '18%', '11%', '10%', '16%', '10%'],
      header: [
        { ttl: 'ID' },
        { ttl: 'Type of Business' },
        { ttl: 'Type of License' },
        { ttl: 'Country' },
        { ttl: 'Description' },
        { ttl: 'Fintech type' },
        { ttl: 'Price', sort: 'price' },
        { ttl: 'From' },
        { ttl: 'Created At' },
        { ttl: '' },
      ],
      body: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'asc',
        loading: false
      },
      rawData: []
    },
    appliedBosTableData: {
      colsWidth: ['4%', '17%', '17%', '16%', '34%', '12%'],
      header: [
        { ttl: '#' },
        { ttl: 'User' },
        { ttl: 'Type of Business' },
        { ttl: 'Type of License' },
        { ttl: '' },
      ],
      body: [],
      paginationData: null
    },
    providersOffers: false
  }),
  getters: {
    getUser: (state, getters, rootState) => {
      return rootState.user;
    }
  },
  actions: {
    fetchUserData({ rootState }) {
      const user = rootState.user;
    },
    async getOffers ({ state, commit, rootGetters }, payload = { page: 1 }) {
      if(!payload.hasOwnProperty('page')){
        let page = payload
        payload = {page}
      }

      let user = rootGetters['bos_business/getUser'];

      const sortParams = '&providers=' + state.providersOffers + '&sort=' + state.offersTableData.sort.name + '&order=' + state.offersTableData.sort.order
      
      let searchParams = ''

      if(payload.hasOwnProperty('searchOfferId') && payload.searchOfferId){
        searchParams += '&offer_id=' + payload.searchOfferId
      }
      
      if(payload.hasOwnProperty('is_top') && payload.is_top){
        searchParams += '&is_top=' + payload.is_top
      }
      
      if(payload.hasOwnProperty('country') && payload.country){
        searchParams += '&country=' + payload.country
      }

      await axios.get('admin/business-offers?page='+ payload.page + sortParams + searchParams)
        .then(({ data }) => {
          commit('setOffersTableData', { data: data, user: user });
        })
    },
    async createOffer ({ dispatch }, payload) {
      try {
        const res = await axios.post('admin/business-offers', payload);
        dispatch('getOffers');
        return res;
      } catch (error) {
        console.error('Error creating offer:', error);
        throw error;
      }
    },
    async updateOffer ({ dispatch }, payload) {
      await axios.post(`admin/business-offers/update/${payload.id}`, payload.form)
        .then(res => {
          dispatch('getOffers')
        })
    },
    async updateNewOffer ({ dispatch }, payload) {
      await axios.post(`admin/business-offers/update-new-request/${payload.id}`, payload.form)
        .then(res => {
          dispatch('getOffers')
        })
    },
    async getAppliedBosData ({ commit }, payload = { page: 1 }) {
      await axios.get('admin/business-offers/applied?page='+ payload.page)
        .then(({ data }) => {
          commit('setAppliedBosTableData', { data: data });
        })
    },
  },
  mutations: {
    setOffersTableData (state, payload) {
      state.offersTableData.paginationData = payload.data;
      state.offersTableData.rawData = [];
      payload.data.data.forEach(el => {
        const elArr = el.slice();
        state.offersTableData.rawData.push(elArr)

        let user = payload.user
        let price = ''
        let license = ''
        let from = ''
        let isRequestToDelete = el[13]
        let newOffer = el[14]
        let sellerName = el[15]
        let sellerContacts = el[16]
        let longDescription = el[17]
        let fintechTypeName = el[18]
        let isTop = el[19]

        if(el[2] && el[8]){
          license = `${el[2]} | №${el[8]}`
          el[2] = license
        }

        el.splice(8, 15);

        if (el[5].price || el[5].wish_price) {
          price = el[5].currency ? `${+el[5].wish_price} ${el[5].currency.code} | ${+el[5].price} ${el[5].currency.code}` : `${+el[5].wish_price} EUR | ${+el[5].price} EUR`
        }

        el.splice(5, 0, fintechTypeName)

        el[6] = price

        if(el[7].from){
          from = el[7].from
        }

        el[7] = from

        if(user != null && user.is_super_admin){
          el.splice(8, 0, sellerName);
        }

        el.push({ type: 'edit-delete-new-delete-request-pdf-toggle-top-actions', id: el[0], newOffer: newOffer, isRequestToDelete: isRequestToDelete, pdf_url: process.env.VUE_APP_BACKEND_API_URL + 'admin/business-offers/generate-pdf/' + el[0], is_top: isTop })
      })
      state.offersTableData.body = payload.data.data;
      if (state.offersTableData.sort.name) state.offersTableData.sort.loading = false
    },
    setAppliedBosTableData (state, payload) {
      state.appliedBosTableData.paginationData = payload.data;
      const tableData =  payload.data.data.map(function(businessOffer) {
        return [
          businessOffer.id, businessOffer.user.full_name, businessOffer.businessOffer.business_type, 
          businessOffer.businessOffer.license_type, {
            type: businessOffer.action_btn.type,
            id: businessOffer.action_btn.id,
            html: 'Show',
          }
        ];
      });
      state.appliedBosTableData.body = tableData;
    },
    setProvidersOffers (state, status) {
      state.providersOffers = status
    },
    setOffersTableHeader (state, header) {
      state.offersTableData.header = header
    },
    setOffersTableColWidth (state, colWidth) {
      console.log(colWidth)
      state.offersTableData.colWidth = colWidth
    },
    addSuperAdminCol(state, is_super_admin) {
      if(is_super_admin) {
        const index = state.offersTableData.header.findIndex(element => element.ttl === 'From');
        
        if(index !== -1) {
          state.offersTableData.header.splice(index + 1, 0, { ttl: 'Seller name' });

          state.offersTableData.colsWidth = ['2%', '10%', '10%', '10%', '8%', '16%', '10%', '10%', '10%', '16%', '10%']
        }
      }
    }
  }
})
