<template>
  <overlay-scrollbars class="page page-personal" ref="os">
    <div class="box-container">
      <div class="transparent-box box_b-info">
        <div class="content" >
          <div class="block block_mt request-table">
            <div class="hdr">
              <div class="ttl">Incorporate License</div>
            </div>
            <Table
              class="offer-requests"
              :options="licenseOfferTableData" :key="licenseOfferTableKey"
              @change-page="getLicenseOffers"
              @editItem="editLicenseOffer"
              @deleteItem="deleteLicenseOffer"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="box-container">
      <div class="transparent-box box box_selectedAcc">
        <div class="content">
          <div class="block block_mt" ref="bosBlock">
            <div class="hdr">
              <div class="ttl">Ready License</div>
            </div>
            <Table 
              :options="businesOffers" 
              :key="businesOffersKey" 
              @change-page="getAppliedBusinesOffers"
              @apply-with-user="cancelBusinesOffer" 
              @show-details-with-user="processDataset" />
          </div>
        </div>
      </div>
    </div>
    <div class="box-container">
      <div class="transparent-box box_b-info">
        <div class="content" >
          <div class="block request-table">
            <div class="hdr offers-hdr">
              <div class="ttl">License offers other services</div>
              <div class="btn create-btn btn__createOffer" @click="editLicenseOfferOtherService">
                Create
              </div>
            </div>
            <Table
              class="offer-requests"
              :options="licenseOfferOtherServicesTableData" :key="licenseOfferOtherServicesTableKey"
              @change-page="getLicenseOfferOtherServices"
              @editItem="editLicenseOfferOtherService"
              @deleteItem="deleteLicenseOfferOtherService"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShowDatasetModal" class="inner-modal__wrapper" @click.self="closeDataset">
      <div class="inner-modal">
        <div class="modal__close">
          <div class="btn" @click.prevent="closeDataset">
            <Close class="img"/>
          </div>
        </div>
        <div class="modal__header">
          <div class="ttl">
            Add additional info
          </div>
        </div>
        <div class="modal__body page-request main">
          <div class="row">
            <div class="label">Note</div>
            <div class="data">
              <textarea name="note" rows="5" class="inpt inpt__ta" v-model="currentDataset.note"/>
            </div>
          </div>
          <div class="row">
            <div class="label">Progress Stage</div>
            <div class="data">
              <input type="text" name="progress_stage" class="inpt" v-model="currentDataset.progress_stage">
            </div>
          </div>
          <div class="row row_files">
            <div class="box box_addFiles">
              <div v-if="getUploadedFiles('docs').length" class="content content_many">
                <draggable v-model="currentDataset.datasetUpload.docs" tag="div" class="draggable">
                <div v-for="(file, key) in getUploadedFiles('docs')" :key="key" class="file">
                  <BlueFile class="img" />
                  <div class="btn btn_del" @click="delFile('docs', key, file)"><Close class="img" /></div>
                  <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'docs')"><BlueDownload class="img" /></div>
                  <div class="desc">{{ file.name }}</div>
                </div>
                </draggable>
                <div class="add">
                  <div class="btn" @click="$refs.docs[currentDataset.datasetUpload.docs.length - 1].click()"><Close class="img" /></div>
                </div>
              </div>
              <div v-else class="content">
                <div class="choose">
                  <div class="btn" @click="$refs.docs[0].click()">Documents</div>
                </div>
              </div>
                <input
                  v-for="(i, key) in currentDataset.datasetUpload.docs"
                  :key="key"
                  type="file"
                  :name="`docs_${key}`"
                  class="hide"
                  ref="docs"
                  @change="addInput($event, 'docs', key)"
                >
            </div>
          </div>
        </div>
        <div class="modal__footer footer">
          <div class="btn btn_submit" @click.prevent="submitDataset">
            Save Data
          </div>
        </div>
      </div> 
    </div>
  </overlay-scrollbars>
</template>

<script>
import Pen from '../components/img/Pen.vue'
import User from '../components/img/User.vue'
import Table from '../components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'
import { mapState, mapActions } from 'vuex'
import BlueFile from './../components/img/BlueFile.vue'
import draggable from 'vuedraggable'
import BlueDownload from './../components/img/BlueDownload.vue'
import Close from './../components/img/Close.vue'

export default {
  components: {
    Pen,
    User,
    Pagination,
    Table,
    BlueFile,
    draggable,
    BlueDownload,
    Close
  },
  data(){
    return{
      initRequest: false,
      licenseOfferTableKey: 0,
      licenseOfferTableData: {
        objName: 'licenseOfferTableData',
        colsWidth: ['10%', '9%', '9%', '9%', '9%', '10%', '10%', '10%', '10%', '9%', '5%'],
        header: [
          { ttl: 'User', info: null },
          { ttl: 'Bank type', info: null },
          { ttl: 'Countries', info: null },
          { ttl: 'License type', info: null},
          { ttl: 'Software type', info: null},
          { ttl: 'Account type', info: null },
          { ttl: 'Membership type', info: null },
          { ttl: 'Operetional type', info: null },
          { ttl: 'Status', info: null },
          { ttl: 'Request created at', info: null },
          { ttl: '', info: null },
        ],
        body: [],
        paginationData: null,
        sort: {
          name: '',
          order: 'desc',
          loading: false
        }
      },
      licenseOfferOtherServicesTableKey: 0,
      licenseOfferOtherServicesTableData: {
        objName: 'licenseOfferOtherServicesTableData',
        colsWidth: ['5%', '25%', '25%', '25%', '20%'],
        header: [
          { ttl: '#', info: null },
          { ttl: 'Name', info: null },
          { ttl: 'Price', info: null },
          { ttl: 'Is visible', info: null},
          { ttl: '', info: null },
        ],
        body: [],
        paginationData: null,
        sort: {
          name: '',
          order: 'desc',
          loading: false
        }
      },
      businesOffersKey: 1,
      businesOffers: {
        colsWidth: ['5%', '15%', '15%', '15%', '30%', '10%', '10%'],
        header: [
          { ttl: '#' },
          { ttl: 'User' },
          { ttl: 'Type of Business' },
          { ttl: 'Type of License' },
          { ttl: 'Country' },
          { ttl: 'Description' },
          { ttl: 'Request' },
          { ttl: 'Detail' }
        ],
        body: [],
        paginationData: null,
      },
      isShowDatasetModal: false,
      currentDataset: this.getEmptyDataset(),
      currentDatasetBusinessOfferId: null,
      currentDatasetUserId: null,
    } 
  },
  created () {
    this.getLicenseOffers()
    this.getLicenseOfferOtherServices()
    this.getAppliedBusinesOffers()
    this.initRequest = true
    const that = this
  },
  methods: {
    toElTop (el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
    getLicenseOffers (page = 1) {
      const that = this
      const basePath = process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers?page=' + page + '&per_page=15'
      const sortParams = '&sort=' + this.licenseOfferTableData.sort.name + '&order=' + this.licenseOfferTableData.sort.order
      axios.get(basePath + sortParams)
        .then(function (response) {
          that.licenseOfferTableData.paginationData = response.data
          that.licenseOfferTableData.body = []
          response.data.data.forEach(el => {
            that.licenseOfferTableData.body.push([
              el.user.full_name ? el.user.full_name : el.user.name + ' | ' + el.user.email + ' | ' + el.user.member_login,
              that.objectToString(el.bank_types, el.other_bank_type),
              that.objectToString(el.countries),
              that.objectToString(el.license_types, el.other_license_type),
              that.objectToString(el.software_types, el.other_software_type),
              that.objectToString(el.account_types, el.other_account_type),
              that.objectToString(el.membership_types, el.other_membership_type),
              that.objectToString(el.operetional_types, el.other_operetional_type),
              el.status.name + ' Progress: ' + el.progress_stage,
              that.getFormattedDate(el.created_at),
              { type: 'edit-delete-pdf-actions', id: el.id, pdf_url: process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/generate-pdf/' + el.id },
            ])
          })
          if (that.licenseOfferTableData.sort.name) that.licenseOfferTableData.sort.loading = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    }, 
    getFormattedDate(value){
      const date = new Date(value);

      const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });

      return formattedDate;
    },
    getLicenseOfferOtherServices (page = 1) {
      const that = this
      const basePath = process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/other-services?page=' + page + '&per_page=15'
      const sortParams = '&sort=' + this.licenseOfferOtherServicesTableData.sort.name + '&order=' + this.licenseOfferOtherServicesTableData.sort.order
      axios.get(basePath + sortParams)
        .then(function (response) {
          that.licenseOfferOtherServicesTableData.paginationData = response.data
          that.licenseOfferOtherServicesTableData.body = []
          response.data.data.forEach(el => {
            that.licenseOfferOtherServicesTableData.body.push([
              el.id,
              el.name,
              el.price,
              el.is_visible,
              { type: 'edit-delete-actions', id: el.id },
            ])
          })
          if (that.licenseOfferOtherServicesTableData.sort.name) that.licenseOfferOtherServicesTableData.sort.loading = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
		objectToString(obj, alt = null){
			let array = [];

			obj.forEach((el) => {
    			if(el.name != 'Other'){
					array.push(el.name)
				}
			});

			if(alt){
				array.push(alt)	
			}

			return array.join(', ')
		},
    /*sortBy (data) {
      this[data.obj].sort.loading = true
      this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
        ? 'desc' : 'asc'
      this[data.obj].sort.name = data.sort
      this.getLicenseOffers(1)
    },*/
    editLicenseOffer (obj) {
      this.$store.commit('setModal', {
        template: 'license-offer',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'license-offers',
          objectType: 'license offers',
          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/update/' + obj.id,
          successfullMessage: 'You successfully updated license offer',
        }
      })
    },
    deleteLicenseOffer (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'license-offers',
          type: 'license offers',
          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/' + obj.id,
          successfullMessage: 'You successfully deleted license offer',
        }
      })
    },
    editLicenseOfferOtherService (obj) {
      let form = {
        name: '',
        price: 0,
        is_visible: 0,
      }

      if(obj.id){
        const item = this.licenseOfferOtherServicesTableData.body.find(el => el[0] == obj.id)
        form = {
          name: item[1],
          price: item[2],
          is_visible: item[3],
        }
      }
      
      this.$store.commit('setModal', {
        template: 'license-offer-other-service',
        options: {
          id: obj.id,
          form: form,
          actionType: 'edit',
          table: 'license-offer-other-services',
          objectType: 'license offer other services',
          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/other-service/' + obj.id,
          successfullMessage: 'You successfully updated license offer',
        }
      })
    },
    deleteLicenseOfferOtherService (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'license-offer-other-services',
          type: 'license offer other services',
          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/license-offers/other-service/' + obj.id,
          successfullMessage: 'You successfully deleted other service for license offer',
        }
      })
    },
    updateCurrentPage(tableName, actionType) {
      let currentPage = 1
      if (actionType == 'edit' || actionType == 'delete') {
        try {
        currentPage = document.getElementsByClassName(tableName)[0]
          .getElementsByClassName('pagination')[0]
          .getElementsByClassName('active')[0]
          .getElementsByClassName('page-link')[0]
          .innerText.trim().replace( /\D+/g, '')
        } catch (e) {
            console.log(e)
        }
      }
      if (tableName == 'license-offers') {
        this.getLicenseOffers(currentPage)
      }
      else if (tableName == 'license-offer-other-services') {
        this.getLicenseOfferOtherServices(currentPage)
      }
    },
    showUserBusinessOffers (payload) {
      this.$router.push({ name: "CustomerDetail", params: { id: payload.id, viewAppliedOffers: true, 'scroll': 'bos' }})
    },
    getAppliedBusinesOffers (page = 1) {
      axios.get('admin/business-offers/applied?page=' + page + '&per_page=15&with_user=1')
        .then(({ data }) => {
          this.businesOffers.paginationData = data
          this.businesOffers.body = data.data
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    async cancelBusinesOffer (payload) {
      await axios.post('admin/business-offers/cancel/' + payload.id + '/' + payload.user_id)
        .then(({ data }) => {
          if (data.canceled) {
            this.businesOffers.body = this.businesOffers.body.filter(el => +el[0] !== +payload.id)
            this.businesOffersKey++
          }
        })
        .catch(errors => {
          console.dir(errors)
        })
    },
    async processDataset(data){
      let businessOfferId = data.id
      let userId = data.user_id

      this.currentDatasetBusinessOfferId = businessOfferId
      this.currentDatasetUserId = userId

      this.currentDataset = await this.getDataset(businessOfferId, userId);

      this.isShowDatasetModal = true;
    },
    closeDataset(){
      this.currentDataset = this.getEmptyDataset();

      this.currentDatasetBusinessOfferId = null

      this.isShowDatasetModal = false;
    },
    async submitDataset(){
      let userId = this.currentDatasetUserId
      let businessOfferId = this.currentDatasetBusinessOfferId
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/business-offers/dataset/'+ businessOfferId + '/' + userId

      if(this.currentDataset.id){
        url += '/' + this.currentDataset.id
      }

      try {
        const response = await axios.post(url, {
          note: this.currentDataset.note,
          progress_stage: this.currentDataset.progress_stage,
        });
        let datasetId = response.data.id;

        await this.processFiles(datasetId)

        this.closeDataset()
      }
      catch (error) {
        console.log(error);
      }
    },
    async processFiles(id){
      let data = new FormData()
      const uploadKeys = Object.keys(this.currentDataset.datasetUpload)

      for (let z = 0; z < uploadKeys.length; z++) {
        const key = uploadKeys[z]

        for (let i = 0; i < this.currentDataset.datasetUpload[key].length; i++) {
          if (this.currentDataset.datasetUpload[key][i].name !== null) {
            let f = {}
            if (this.currentDataset.datasetUpload[key][i].type == 'server') {
              f = JSON.stringify(this.currentDataset.datasetUpload[key][i])
            } else {
              data.append(key + '_names[' + i + ']', this.currentDataset.datasetUpload[key][i].name)
              f = this.currentDataset.datasetUpload[key][i].file
            }
            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post('admin/business-offers/dataset/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
          
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    async getDataset(businessOfferId, userId){
      try {
        const response = await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/business-offers/dataset/' + businessOfferId + '/' + userId);
        let dataset = response.data;
        let datasetId = dataset.id;
        
        dataset.datasetUpload = {docs: await this.getDatasetDocs(datasetId)};

        return dataset;
      }
      catch (error) {
        console.log(error);

        return this.getEmptyDataset();
      }
    },
    async getDatasetDocs(id) {
      if(id) {
        try {
          const response = await axios.get('admin/business-offers/dataset/' + id + '/get-file/docs');
          
          let docsData = response.data
          
          if (docsData.length > 0) {
            return docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              };
            });
          }

          return [{
            id: null,
            uuid: null,
            name: null,
            type: '',
            url: null,
            order: null
          }]
        }
        catch (error) {
          console.dir(error);
        }
      }
    },
    getEmptyDataset(){
      return {
        progress_stage: 0,
        note: '',
        datasetUpload: {
          docs: [{ name: null }],
        }
      }
    },
    getUploadedFiles(el) {
      return this.currentDataset.datasetUpload[el].filter(e => e.name !== null)
    },
    delFile(name, key, file) {
      if (this.currentDataset.datasetUpload[name].length > 1) {
        this.currentDataset.datasetUpload[name].splice(key, 1)
      } else {
        this.currentDataset.datasetUpload[name][0].name = null
      }

      if(file.id && this.currentDataset.id){
        axios.post('admin/business-offers/dataset/' + this.currentDataset.id +'/delete-file', {
        collection: name,
        media_id: file.id,
      })
        .then(function () {
          
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name
        if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')
          return false
        }

        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx', 'zip', 'rar', '7z', 'tar']

        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          return false
        }

        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 5) {
          this.$noty.error('File is too large. Maximum - 5MB.')
          return false
        }

        this.currentDataset.datasetUpload[name] = this.currentDataset.datasetUpload[name].filter(e => e.name !== null)
        if (this.$refs[name].length < 2) {
          this.currentDataset.datasetUpload[name].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 2 files')
        }
      }
    },
    downloadFile(file, type) {
      const id = this.currentDataset.id

      if(file.url){
        this.openFile(file.url)
      }
      else if(id){
        axios.post('admin/business-offers/dataset/' + id +'/get-file-link', {
        collection: type,
        media_id: file.id,
      })
        .then(function (response) {
          if (response.data) {
            const url = response.data
            var fileURL = response.data;
            this.openFile(fileUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
  watch: {
		'$store.state.deleteItemFromTableData': function(data) {
        this.updateCurrentPage(data.table, data.actionType)
    },
    '$store.state.editItemFromTableData': function(data) {
        this.updateCurrentPage(data.table, data.actionType)
    }
	},
}
</script>

<style lang="scss" scoped>
.page-personal {
  .content .block .ttl{
    font-size: 20px;
  }
  .box {
    &-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &_rate {
      position: relative;
      height: 7rem;
      height: 11vh;
      margin-top: 6rem;
      margin-top: 7vh;
      margin-bottom: 4rem;
      margin-bottom: 6vh;

      .box-inside {
        width: 95%;
        height: 11rem;
        height: calc(100% + 6vh);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(113.24deg, #1763fb 16.01%, #1763fb 106.71%);
        box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
        border-radius: 14px;
        display: flex;

        .content {
          flex-grow: 1;

          .scale-box {
            width: 100%;
          }
        }

        .img {
          height: 14rem;
          height: calc(100% + 6vh);
          position: relative;
          top: 100%;
          transform: translateY(-96%);
          margin: auto 3rem;
          margin: auto 3vw;
        }
      }
    }

    &_my-profile {
      position: relative;
      width: 100%;
      padding-right: 4.25rem;

      .header {
        display: flex;

        .title {
          flex-grow: 1;
        }

        .tmblr {
          display: flex;
          align-items: center;
          padding: 0 .25rem;

          .txt {
            color: rgba(255, 255, 255, 0.5);
          }

          .btn {
            &-tmblr {
              position: relative;
              width: 1.5rem;
              height: .5rem;
              background-color: rgba(255, 255, 255, 0.12);
              border-radius: 2rem;
              margin: auto 1rem;

              &-l::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-50%, -50%);
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: #ffffff;
              }

              &-r::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(50%, -50%);
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: #ffffff;
              }
            }
          }

          &-hide {
            .txt {
              color: #1763fb;
            }

            .btn-tmblr-r::before {
              background-color:  #1763fb;
            }
          }
        }
      }

      .content {
        padding: 0 0 1rem 0;
        padding: 0 0 1.5vh 0;
        display: flex;

        &.blur {
          filter: blur(7px);
        }

        .card {
          &-big {
            width: 100%;
            height: 11.5rem;
            display: flex;
            align-items: flex-end;
            background-image: url('/img/two_line.png');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;
            padding: 1.25rem 1.75rem;

            svg.img {
              width: 3.5rem;
              height: 4rem;
              margin-right: 1rem;
            }

            .data {
              flex-grow: 1;

              .ttl {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.5);
                padding-bottom: .25rem;
              }

              .desc {
                font-size: 1.25rem;
                font-weight: 600;
              }
            }
          }
        }

        .col {
          &-1 {
            width: 27rem;
            width: 21vw;
            padding-right: .5rem;
          }

          &-2 {
            flex-grow: 1;
            padding-left: .5rem;
            padding-right: .5rem;
            display: flex;

            .subcol {
              padding: 0 .5rem;

              &-1 {
                width: 30%;
              }

              &-2,
              &-3 {
                width: 35%;
              }
            }
          }
        }
      }
    }

    &_b-info {
      flex-grow: 1;
      position: relative;
      // background-color: #272E35;
      margin-right: 2rem;

      .person-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .card {
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      .header {
        padding-right: 4.25rem;

        .title {
          &::before {
            background-color: rgb(255 255 255 / 25%);
          }
        }
      }

      .content {
        .row {
          display: flex;
          justify-content: space-between;

          .card {
            background: rgba(23, 99, 251, 0.1);
            width: 30%;

            &-min {
              width: 20%;
            }
          }
        }
      }
    }

    &_comp-logo {
      width: 23rem;
      position: relative;
      background-color: #272E35;
      overflow: hidden;
      padding-bottom: 1rem;

      .dots {
        position: absolute;

        &-top {
          top: -5px;
          right: 7px;
          width: 2rem;
          opacity: .5;
        }

        &-left {
          top: 6.5rem;
          left: 28px;
          width: 1.5rem;
          opacity: .5;
        }

        &-bottom {
          bottom: -12px;
          right: 1.75rem;
          width: 2.5rem;
          transform: rotate(90deg);
          opacity: 0.5;
        }
      }

      .header {
        .title {
          &::before {
            background-color: rgb(255 255 255 / 25%);
          }
        }
      }

      .logo {
        flex-grow: 1;
        width: 14rem;
        height: 14rem;
        margin: .25rem auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        position: relative;
        z-index: 1;

        .btn {
          width: 3.25rem;
          height: 3.25rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          &_update {
            background-color: #1763fb;

            svg {
              width: 1.75rem;
              height: 1.75rem;
            }
          }

          &_remove {
            background-color: #B03636;

            svg {
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }
      }
    }
  }
}
.create-btn {
  border-radius: .5rem;
  padding: .5rem 1.5rem;
  background-color: #1763fb;
  max-width: 120px;
  text-align: center;
  margin: 12px 0;
}
.scale {
  position: relative;
  width: 100%;
  height: 1rem;
  margin: 1rem 0 3.5rem 0;
  border-radius: 3rem;
  background-color: rgba(255, 255, 255, 0.12);

  .progress {
    position: relative;
    height: 100%;
    border-radius: 3rem;
    background-color: #ffffff;
    background-image: url('/img/rate_el.png');
    background-position: 0 0;
    background-repeat: repeat-x;
    background-size: auto 100%;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 5rem;
      background-color: #E2F4EC;
      background-image: url('/img/smile.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 65%;
    }

    .note {
      position: absolute;
      bottom: -3.25rem;
      right: 0;
      transform: translateX(50%);
      background-color: #094F4D;
      color: #ffffff;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 7px 1rem 6px;
      border-radius: 6px;

      &::before {
        content: '';
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: .75rem;
        height: .75rem;
        background-color: #094F4D;
        border-radius: 2px;
      }
    }
  }
}

.btn {
  &-edit {
    width: 2.75rem;
    height: 2.75rem;
    position: absolute;
    top: .75rem;
    right: .75rem;
    background: #22252B;
    border-radius: 6px;
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg.img {
      width: 1rem;
      height: 1rem;
    }
  }
  &-save {
    width: 3.75rem;
    height: 2.75rem;
    position: absolute;
    top: .75rem;
    right: .75rem;
    background: #22252B;
    border-radius: 6px;
    padding: .75rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg.img {
      width: 1rem;
      height: 1rem;
    }
  }
}

.form-control {
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: #1C2126;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin: auto 0rem;
  border: 1px solid #2F363D;
  border-radius: .75rem;
  box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.02);
  outline: none;
  -webkit-appearance: none;

  &::placeholder {
    color: #B9B9B9;
  }
}

.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.transparent-box {
  width: 100%;
  border-radius: 14px;
  margin: 1rem 0;
}

.tbl_header .col {
  font-size: 10px !important;
}
.inner-modal__wrapper{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  .modal__body{
    display: block;
  }

  .inner-modal{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #272E35;
    max-width: 100%;
    width: 520px;
    .ttl, .label{
      color: #fff
    }
    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;
      flex-wrap: wrap;

      .label {
        width: 100%;
        padding-right: .75rem;
        font-weight: bold;
      }

      .data {
        flex-grow: 1;
        width: 100%;

        .inpt {
          width: 100%;
          color: #fff;

          &__ta {
            height: 7rem;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }
    .row{
      &_files {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .box_addFiles {
          width: 30%;
          //width: calc(50% - .75rem);
          //margin: .75rem 0;
          //padding: 1rem 1.75rem;
          //background: #22252B;
          box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
          display: flex;
          flex-direction: column;

          .ttl {
            padding-bottom: .25rem;
          }

          .separ {
            width: 3rem;
            height: .25rem;
            background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            border-radius: 14px;
            margin: .25rem 0;
          }

          .content {
            padding: 1rem;
            flex-grow: 1;
            //margin-top: 1rem;
            border: 1px dashed #1763fb;
            box-sizing: border-box;
            filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
            border-radius: 14px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .choose {
              text-align: center;

              .btn {
                padding: 0.5rem;
                background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                border-radius: 6px;
                color: #fff;
                font-size: 13px;
              }

              .desc {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
              }
            }

            &_many {
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0.75rem 6px;

              .draggable {
                display: flex;
                padding: 0;
                flex-wrap: wrap;
              }

              .file {
                position: relative;
                text-align: center;
                width: 4.5rem;
                max-width: 4.5rem;

                svg.img {
                  width: 3rem;
                  height: 3rem;
                  margin: 0 auto;
                  cursor: pointer;
                  fill: #1763fb;
                  path{
                    fill: #1763fb;
                  }
                }
                .btn {
                  &_del {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    background-color: #B03636;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 40%;
                      height: 40%;
                    }
                  }

                  &_download {
                    position: absolute;
                    top: 1.7rem;
                    right: 1rem;
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 90%;
                      height: 90%;
                    }
                  }
                }

                .desc {
                  font-size: 10px;
                  color: #fff;
                  word-break: break-word;
                }
              }

              .add {
                text-align: center;
                width: 2.5rem;
                max-width: 2.5rem;

                .btn {
                  width: 2.5rem;
                  height: 2.5rem;
                  border-radius: 50%;
                  background: linear-gradient(270deg, #1763fb 0%, #1763fb 100%), #1763fb;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg.img {
                    width: 45%;
                    height: 45%;
                    transform: rotate(45deg);
                  }
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>